import { createTheme, PaletteOptions } from '@mui/material/styles'

import {
  ACTION,
  BLACK,
  BLUE1,
  BLUE2,
  BORDER,
  ERROR,
  ERROR_LIGHT,
  GREEN,
  GREEN2,
  GREY0,
  GREY1,
  GREY2,
  GREY3,
  GREY4,
  GREY5,
  GREY6,
  GREY7,
  GREY8,
  GREY9,
  GREY10,
  GREY11,
  MKT,
  PURPLE1,
  PURPLE2,
  RED,
  RED2,
  SUCCESS,
  WARNING,
  WHITE,
} from './constants'

// *********************************************************************
//* palette extension
// *********************************************************************

const inputRoot = {
  backgroundColor: WHITE,
  border: '1px solid',
  borderRadius: '6px',
  color: GREY7,
  minHeight: '42px',
  borderColor: BORDER,
  padding: '6px 12px',
  '&:hover': {
    background: GREY0,
  },
  '&.Mui-focused': {
    backgroundColor: WHITE,
    borderColor: ACTION,
    boxShadow: `0 0 0 2px ${ACTION}50`,
  },
  '& ::-webkit-input-placeholder': {
    color: GREY5,
    opacity: 1,
  },
  '&.MuiInput-underline': {
    // Incase we need to have underline somewhere on the website, copy the below code and add color and
    // add as classes for the TextField
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}

const muiFiveTheme = createTheme({
  palette: {
    primary: { main: ACTION, contrastText: WHITE },
    secondary: { main: GREY8 },
    error: { main: ERROR, light: ERROR_LIGHT },
    warning: { main: WARNING },
    success: { main: SUCCESS },
    common: { black: BLACK, white: WHITE },
    grey: {
      grey0: GREY0,
      grey1: GREY1,
      grey2: GREY2,
      grey3: GREY3,
      grey4: GREY4,
      grey5: GREY5,
      grey6: GREY6,
      grey7: GREY7,
      grey8: GREY8,
      grey9: GREY9,
      grey10: GREY10,
      grey11: GREY11,
    },
    answerOption: {
      primary: '#fff',
      secondary: 'rgba(88, 110, 224, 0.06)',
      active: 'rgba(88, 110, 224, 0.12)',
      tertiary: '#f2f3f5',
      border: '#72767d',
      text: '#000',
      activeText: ACTION,
    },
    background: {
      colored: {
        mkt: MKT,
        action: ACTION,
        border: BORDER,
        red: RED,
        green: GREEN,
        purple1: PURPLE1,
        purple2: PURPLE2,
        blue1: BLUE1,
        blue2: BLUE2,
      },
    },

    badge: {
      orderCounter: ACTION,
      orderCounterBG: 'rgb(88 110 224 / 12%)',
      los: '#f6f6f9',
    },

    border: {
      primary: BORDER,
      action: ACTION,
      green: GREEN,
    },

    contentDivider: {
      primary: '#f1f1f1',
      secondary: '#f1f1f1',
      tertiary: '#ccc',
    },

    gradient: {
      primary: `linear-gradient(to right, ${PURPLE1}, ${BLUE1})`,
    },

    graphs: {
      cat: {
        swatch0: '#003f5c',
        swatch1: '#2f4b7c',
        swatch2: '#665191',
        swatch3: '#a05195',
        swatch4: '#d45087',
        swatch5: '#f9586a',
        swatch6: '#ff7c43',
        swatch7: '#ffa600',
      },
      con: {
        swatch0: '#586ee0',
        swatch1: '#6e7be4',
        swatch2: '#8189e8',
        swatch3: '#9396ec',
        swatch4: '#a3a4f0',
        swatch5: '#b3b3f4',
        swatch6: '#c3c1f8',
        swatch7: '#d2d0fb',
        swatch8: '#e1dfff',
      },
    },

    button: {
      action: ACTION,
    },

    buttons: {
      action: ACTION,
      black: BLACK,
      grey3: GREY3,
      grey4: GREY4,
      error: ERROR,
      warning: WARNING,
      white: WHITE,
      gradient: `linear-gradient(90deg, ${PURPLE1}, ${BLUE1})`,
      icon: 'transparent',
    },
    // copied directly, needs to be looked into
    highlight: {
      primary: '#292b2f',
      secondary: '#202225',
      tertiary: '#37393f',
      quaternary: '#202225',
      quinary: '#4f545b',
    },

    // copied directly, needs to be looked into
    icon: {
      primary: '#565656',
      secondary: '#ccc',
      tertiary: '#fff',
      base: '#21ce99',
      background: {
        primary: '#292b2f',
      },
      light: {
        hover: '#ffffff',
      },
    },

    // copied directly, needs to be looked into
    loader: {
      primary: '#4c4c4c',
      secondary: '#333',
    },

    checkbox: {
      background: WHITE,
      text: GREY7,
      svg: GREY7,
      border: GREY5,
      hover: {
        background: `${ACTION}0f`,
        border: `${ACTION}80`,
        text: GREY7,
        checkbox: ACTION,
        svg: ACTION,
      },
      active: {
        background: `${ACTION}1f`,
        borderColor: `${ACTION}`,
        text: ACTION,
        border: ACTION,
      },
    },

    list: {
      background: WHITE,
      text: GREY6,
      hover: {
        background: `${ACTION}0f`,
      },
    },

    modal: {
      backgroundHeader: WHITE,
      backgroundFooter: GREY1,
      primary: '#fff',
      header: '#111',
      headerText: '#999',
      background: {
        primary: '#f2f3f5',
        secondary: '#ffffff',
      },
      checkbox: {
        primary: '#e2e5e8',
        secondary: '#72767d',
      },
    },

    fullscreenModal: {
      background: GREY1,
      backgroundNavbar: WHITE,
      titleText: GREY7,
    },

    settingModal: {
      backgroundMenu: WHITE,
      headerTextMenu: GREY4,
      backgroundMain: GREY1,
      titleMain: GREY7,
      headerTextMain: GREY6,
      helperTextMain: GREY5,
    },

    drawer: {
      backgroundMenu: WHITE,
      boxShadow:
        '0px 8px 10px -5px #00000033, 0px 16px 24px 2px #00000024, 0px 6px 30px 5px #0000001f',
      iconButtonTextMenu: GREY5,
      iconButtonBackground: `${ACTION}1f`,
      iconButtonRightBar: ACTION,
      backgroundMain: GREY1,
      titleText: GREY7,
      active: {
        iconButtonTextMenu: ACTION,
      },
    },

    cardDisplay: {
      background: WHITE,
    },

    cardMiddle: {
      background: WHITE,
      boxShadow: '0 2px 10px #00000014',
    },

    cardData: {
      background: WHITE,
      title: GREY7,
      helperText: GREY5,
    },

    cardContent: {
      background: WHITE,
      title: GREY7,
      helperText: GREY5,
      borderInstitutionLogo: WHITE,
    },

    cardInstitution: {
      background: WHITE,
      title: GREY7,
      helperText: GREY5,
      borderInstitutionLogo: WHITE,
    },

    snackbar: {
      information: ACTION,
      success: SUCCESS,
    },

    badges: {
      action: ACTION,
      special: `linear-gradient(90deg, ${PURPLE1}, ${BLUE1})`,
    },

    menuItem: {
      background: WHITE,
      text: GREY7,
      active: {
        background: `${ACTION}1f`,
        text: ACTION,
      },
      hover: {
        background: `${ACTION}0f`,
      },
    },

    tableList: {
      backgroundHeader: 'transparent',
      headerText: GREY6,
      listItemBackground: WHITE,
      hover: {
        background: `${ACTION}0f`,
      },
    },

    pagination: {
      active: {
        background: `${ACTION}1f`,
        border: `${ACTION}80`,
        text: ACTION,
      },
      hover: {
        background: `${ACTION}0f`,
      },
    },

    panel: {
      expansionIcon: '#999',
      background: '#f2f3f5',
      hover: '#f9f9f9',
      text: {
        primary: '#555',
      },
    },
  } as PaletteOptions,
  typography: {
    fontFamily: 'Lato',
    color: {
      red: RED2,
      action: ACTION,
      green: GREEN,
      green2: GREEN2,
    },
  },
  shade: {
    answerOption: {
      primary:
        '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 0px 8px 0px rgba(0,0,0,0.14), 0px 0px 8px 0px rgba(0,0,0,0.12)',
    },
    tabBody: '0 0px 5px rgba(0, 0, 0, 0.3)',
    note: '0 0px 5px rgba(0, 0, 0, 0.3)',
    panelCard:
      'rgba(34,34,34,0) 60%, rgba(68, 68, 68, 0.2) 66%, rgba(123, 123, 123, 0.85) 80%, rgba(105, 105, 105) 99%',
    panelCardIe:
      'progid:DXImageTransform.Microsoft.gradient( startColorstr="#00222222", endColorstr="#989898",GradientType=0 )',
    modal:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
  },

  // *********************************************************************
  //* component overrides
  // *********************************************************************

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#2c2f33',
          lineHeight: '1.4rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: SUCCESS,
        },
        filledInfo: {
          backgroundColor: ACTION,
        },
        filledError: {
          backgroundColor: ERROR,
        },
        filledWarning: {
          backgroundColor: WARNING,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontFamily: 'Montserrat',
          fontWeight: 600,
          color: GREY8,
          fontSize: '24px',
        },
        h5: {
          fontFamily: 'Titillium Web',
          fontWeight: 600,
          color: GREY7,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textTransform: 'none',
        },
        contained: {
          color: WHITE,
          background: ACTION,
          '&:hover': {
            background: ACTION,
          },
          '&:disabled': {
            background: '#ffffff61',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: `${ACTION}0f`,
            },
          },
          '&:hover': {
            backgroundColor: `${ACTION}0f`,
          },
        },
        head: {
          '&:hover': {
            backgroundColor: GREY1,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          border: `1px solid ${BORDER}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            background: GREY1,
          },
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            background: WHITE,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: '#111',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&$disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#202225',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        input: {
          padding: 0,
        },
        adornedEnd: {
          padding: 0,
          paddingRight: 0,
          paddingLeft: '12px',
          '&.MuiOutlinedInput-adornedStart': {
            paddingLeft: 0,
          },
          '& > svg': { marginRight: '8px' },
        },
        adornedStart: {
          padding: 0,
          paddingLeft: 0,
          '& > svg': { marginLeft: '8px' },
        },
        notchedOutline: {
          border: '1px solid',
          borderColor: 'transparent',
          borderRadius: '5px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: { ...inputRoot, border: 'none' },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: inputRoot,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: inputRoot,
        input: {
          padding: '0',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          height: '100%',
        },
        positionEnd: {
          marginLeft: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: `${ACTION}0f`,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: WHITE,
          color: GREY7,
          borderRadius: '6px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: GREY6,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#000',
          color: '#fff',
          fontSize: '13px',
          padding: '7px 10px',
        },
        arrow: {
          color: '#000',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorInherit: {
          minWidth: '52px',
          color: GREY5,
          '&.Mui-selected': {
            color: ACTION,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: ACTION,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: ACTION,
          color: WHITE,
          '&:hover': {
            backgroundColor: ACTION,
            color: WHITE,
          },
        },
        clickable: {
          '&:hover': {
            backgroundColor: ACTION,
          },
        },
        deleteIcon: {
          color: WHITE,
          transition: '0.2s all ease',
          borderRadius: '50%',
          '&:hover': {
            color: ACTION,
            backgroundColor: WHITE,
            transition: '0.2s all ease',
          },
        },
      },
    },
  },
})

// *********************************************************************
//* body styling
// *********************************************************************
const MuiFiveGlobalStyles = {
  a: {
    color: ACTION,
  },
  p: {
    margin: '0px',
  },
  body: {
    backgroundColor: WHITE,
    color: GREY6,
    fontFamily: 'Lato',
    overflow: 'overlay',
    margin: 0,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.43,
  },
  '*:not(.react-flow__node):not(.react-flow__node *)::-webkit-scrollbar': {
    width: '0.4rem',
  },
  '*::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '6px',
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '*::-webkit-scrollbar-thumb': {
    outline: 'none',
    backgroundColor: 'transparent',
    borderRadius: '6px',
    border: '0px',
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
  '*:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#70707090',
    borderColor: '#111',
    borderRadius: '6px',
  },
}
export { MuiFiveGlobalStyles, muiFiveTheme }
export * from './constants'
export * from './theme'
export * from './types'
